import React, { useState } from "react";

function DesktopFilters({
  appCategoriesNodes,
  setFilterArr,
  filterArr,
  setStatusFilterArr,
  statusFilterArr,
  extraClasses,
}) {
  const handleCleanFilter = () => {
    setFilterArr([]);
    setStatusFilterArr([]);
  };

  const handleFilter = (categoryId) => {
    if (filterArr.indexOf(categoryId) >= 0) {
      const filterArrCopy = [...filterArr];
      filterArrCopy.splice(filterArr.indexOf(categoryId), 1);
      setFilterArr(filterArrCopy);
    } else {
      const filterArrCopy = [...filterArr];
      filterArrCopy.push(categoryId);
      setFilterArr(filterArrCopy);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilterArr.indexOf(status) >= 0) {
      const statusFilterArrCopy = [...statusFilterArr];
      statusFilterArrCopy.splice(statusFilterArr.indexOf(status), 1);
      setStatusFilterArr(statusFilterArrCopy);
    } else {
      const statusFilterArrCopy = [...statusFilterArr];
      statusFilterArrCopy.push(status);
      setStatusFilterArr(statusFilterArrCopy);
    }
  };

  const isFilterSelected = (applicationId) => {
    if (filterArr.indexOf(applicationId) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const isStatusFilterSelected = (status) => {
    if (statusFilterArr.indexOf(status) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`${extraClasses} min-w-[190px] flex-col gap-y-3 lg:flex`}>
      <div
        className="cursor-pointer text-sm font-bold leading-[20px] text-typography-heading underline"
        onClick={handleCleanFilter}
      >
        Reset Filters:
      </div>
      <div className="text-xl font-bold leading-[30px] text-typography-heading ">
        Categories:
      </div>
      <StatusFilter
        label="New!"
        name="new"
        handleStatusFilter={handleStatusFilter}
        isStatusFilterSelected={isStatusFilterSelected("new")}
        selectedClass="rounded-[100px] px-2 pt-[2px] font-bold bg-[#673DEC] text-white max-w-max"
      />
      <StatusFilter
        label="Coming Soon"
        name="coming soon"
        handleStatusFilter={handleStatusFilter}
        isStatusFilterSelected={isStatusFilterSelected("coming soon")}
        selectedClass="rounded-[100px] px-2 pt-[2px] font-bold bg-[#83B813] text-white"
      />
      {appCategoriesNodes.map((application) => (
        <DesktopFilter
          application={application}
          handleFilter={handleFilter}
          isFilterSelected={isFilterSelected(application.id)}
        />
      ))}
    </div>
  );
}

const DesktopFilter = ({ application, handleFilter, isFilterSelected }) => {
  return (
    <div
      className="flex cursor-pointer items-center gap-x-2 text-base text-typography-heading"
      onClick={() => handleFilter(application.id)}
    >
      {isFilterSelected && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle cx="9" cy="9" r="9" fill="url(#paint0_linear_2613_2520)" />
          <path
            d="M6.2998 8.99995L8.0998 10.8L11.6998 7.19995"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2613_2520"
              x1="0"
              y1="18"
              x2="21.2821"
              y2="5.66089"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#673DEC" />
              <stop offset="1" stop-color="#51E7FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <span className={`${isFilterSelected ? "font-bold" : ""} `}>
        {application.title}
      </span>
    </div>
  );
};

const StatusFilter = ({
  label,
  name,
  handleStatusFilter,
  isStatusFilterSelected,
  selectedClass,
}) => {
  return (
    <div
      className="flex cursor-pointer items-center gap-x-2 text-base text-typography-heading"
      onClick={() => handleStatusFilter(name)}
    >
      {isStatusFilterSelected && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle cx="9" cy="9" r="9" fill="url(#paint0_linear_2613_2520)" />
          <path
            d="M6.2998 8.99995L8.0998 10.8L11.6998 7.19995"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2613_2520"
              x1="0"
              y1="18"
              x2="21.2821"
              y2="5.66089"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#673DEC" />
              <stop offset="1" stop-color="#51E7FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <span className={`${isStatusFilterSelected ? `${selectedClass}` : ""}`}>
        {label}
      </span>
    </div>
  );
};

export default DesktopFilters;

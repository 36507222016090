import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";

const AccelerateYourBusiness = ({ headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div>
            <StaticImage
              src="../../images/0.0 Repeating Modules/Accelerate Your Business.png"
              loading="lazy"
              width={560}
              height={560}
            />
          </div>
          <div>
            <HeadingTag>Accelerate Your Business</HeadingTag>
            <p>
              Real Synch makes it easy to automate and streamline your workflow
              and data entry tasks, plus view your team’s activities.
            </p>
            <ul className="styled-list-checkmark">
              <li>Cloud-based—no need to download software</li>
              <li>Quick and easy set-up—takes less than a minute</li>
              <li>Fast data sync for your leads, contacts, and activities</li>
              <li>Built-in automations that improve workflow</li>
              <li>Usage and ROI reporting + custom CRM audit included</li>
              <li>Plus, dedicated support from the Real Synch team</li>
            </ul>
            <ButtonGhost
              href="/like-zapier-real-estate-agents/"
              text="Explore Features"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccelerateYourBusiness;

import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
// import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({
  heading,
  headingLevel,
  ctaLink,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container">
      <section
        className={`rounded-4xl bg-primary-900 px-8 py-20 text-center md:px-16 md:py-20 md:text-left ${
          className || ""
        }`}
      >
        <div className="items-end justify-between md:flex md:space-x-10 lg:space-x-20">
          <div className="mb-6 max-w-[720px] md:mb-0">
            <HeadingTag className="mb-4 bg-gradient-to-r from-primary-700 via-primary-400 to-primary-400 bg-clip-text text-transparent">
              {heading || ["Real Automation Starts Here"]}
            </HeadingTag>
            <p className="mb-0 text-gray-50">
              Agents—are you ready to integrate and automate? Partners—do you
              have a system you’d like to connect with Real Synch? Let’s begin!
            </p>
          </div>

          <ButtonSolid
            altStyle={2}
            modal="modal-contact"
            text="Schedule a Consult"
          />
        </div>
      </section>
    </div>
  );
};

export default CTA;

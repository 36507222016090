import React from "react";
import MicroModal from "micromodal";
import styled from "@emotion/styled";
import tw from "twin.macro";
import "react-tabs/style/react-tabs.css";
import DesktopFilters from "../Integrations/DesktopFilters";

const StyledModal = styled.div`
  .modal {
    &[aria-hidden="false"] {
      .overlay {
        animation: mmfadeIn 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="false"] {
      .content-wrapper {
        animation: mmslideInLeft 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .overlay {
        animation: mmfadeOut 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
    &[aria-hidden="true"] {
      .content-wrapper {
        animation: mmslideOutLeft 0.5s cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
`;

const FiltersModal = ({
  appCategoriesNodes,
  setFilterArr,
  filterArr,
  setStatusFilterArr,
  statusFilterArr,
}) => {
  if (typeof window !== "undefined") {
    MicroModal.init({
      openTrigger: "data-modal-open",
      closeTrigger: "data-modal-close",
      disableFocus: true,
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  }

  return (
    <StyledModal>
      <div
        className="modal relative z-50 hidden"
        id="modal-filters"
        aria-hidden="false"
      >
        <div
          className="overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-start bg-black/50 outline-none"
          tabIndex="-1"
          data-modal-close
        >
          <div
            className="content-wrapper mr-auto h-[640px] w-full max-w-[320px] overflow-auto bg-white"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex w-full items-center justify-between px-4 pt-6">
              <div className="text-3xl font-bold text-gray-900">Filters</div>
              <svg
                className="cursor-pointer"
                data-modal-close
                width="8"
                height="15"
                viewBox="0 0 8 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13.5L1 7.5L7 1.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <DesktopFilters
              appCategoriesNodes={appCategoriesNodes}
              setFilterArr={setFilterArr}
              filterArr={filterArr}
              setStatusFilterArr={setStatusFilterArr}
              statusFilterArr={statusFilterArr}
              extraClasses="flex px-4 pt-6"
            />
          </div>
        </div>
      </div>
    </StyledModal>
  );
};
export default FiltersModal;

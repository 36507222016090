import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import DesignedForRealtors from "../components/Repeating/DesignedForRealtors";
import AccelerateYourBusiness from "../components/Repeating/AccelerateYourBusiness";
import CallToAction from "../components/Repeating/CTA";
import { mapEdgesToNodes } from "../lib/helpers";
import IntegrationsHeader from "../components/Integrations/IntegrationsHeader";
import DesktopFilters from "../components/Integrations/DesktopFilters";
import FiltersModal from "../components/Modal/ModalFilters";
import MobileFilters from "../components/Integrations/MobileFilters";

const Page = ({ data }) => {
  const appNodes = (data || {}).apps ? mapEdgesToNodes(data.apps) : [];
  const appCategoriesNodes = (data || {}).apps
    ? mapEdgesToNodes(data.appCategories)
    : [];
  const [filterArr, setFilterArr] = useState([]);
  const [statusFilterArr, setStatusFilterArr] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Layout>
      <SearchEngineOptimization
        title="Real Estate Software Integrations | Real Synch"
        description="Integrate the systems your real estate team uses for lead generation, transaction management, marketing, and more. Create a better workflow in 4 easy steps."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 bg-primary-200 pb-20 md:mb-32 md:pb-32">
        <IntegrationsHeader
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          iconUrl={data.searchIcon.publicURL}
          heroImageUrl={data.heroImage.publicURL}
        />
        <MobileFilters
          iconUrl={data.filterIcon.publicURL}
          setFilterArr={setFilterArr}
          setStatusFilterArr={setStatusFilterArr}
        />
        {/* <div className="flex justify-between bg-white px-5 py-10">
          <div
            className="flex cursor-pointer gap-x-3 text-typography-heading"
            data-modal-open="modal-filters"
          >
            <img src={data.filterIcon.publicURL} />
            <div>Filters</div>
          </div>
          <div
            className="cursor-pointer text-sm font-bold leading-[20px] text-typography-heading underline"
            onClick={handleCleanFilter}
          >
            Reset Filters:
          </div>
        </div> */}
        <div className="container pt-18">
          <div className="flex gap-x-18">
            <DesktopFilters
              appCategoriesNodes={appCategoriesNodes}
              setFilterArr={setFilterArr}
              filterArr={filterArr}
              setStatusFilterArr={setStatusFilterArr}
              statusFilterArr={statusFilterArr}
              extraClasses="hidden"
            />
            <div className="mb-16 grid w-full grid-cols-2 gap-4 md:mb-26 md:grid-cols-3 md:gap-6">
              {appNodes
                .filter((appNode) => {
                  if (filterArr.length > 0) {
                    return appNode.appCategories.some(
                      (r) => filterArr.indexOf(r.id) >= 0
                    );
                  } else {
                    return true;
                  }
                })
                .filter((appNode) => {
                  if (statusFilterArr.length > 0) {
                    // return appNode.statusText.some(
                    //   (r) => statusFilterArr.indexOf(r.id) >= 0
                    // );
                    return statusFilterArr.includes(appNode.statusText);
                  } else {
                    return true;
                  }
                })
                .filter((appNode) => {
                  // console.log(appNode.title.toLowerCase());
                  return appNode.title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                })
                .map((app, i) => {
                  return app.slug ? (
                    <Link
                      to={`/${app.slug.current}/`}
                      className="relative flex h-32 items-center justify-center rounded-4xl bg-white px-[10px] pl-[4px] shadow-5xl md:h-[168px] md:pr-[4px]"
                      key={i}
                    >
                      {/* START: APP STATUS BADGE */}
                      {app.statusText !== null &&
                      app.statusText !== "coming soon" ? (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-primary-700 px-[10px] py-[4px] text-sm text-xs  font-medium capitalize text-white md:top-[15px] md:right-[15px] ">{`${app.statusText}`}</span>
                      ) : app.statusText === "coming soon" ? (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-[#83B813] px-[10px] py-[4px] text-sm  text-xs font-medium capitalize text-white md:top-[15px] md:right-[15px]">{`${app.statusText}`}</span>
                      ) : (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-transparent px-[10px] py-[4px] text-sm  text-xs font-medium  text-transparent md:top-[15px] md:right-[15px]">{`${app.statusText}`}</span>
                      )}
                      {/*END: APP STATUS BADGE */}

                      {app.logo && app.logo.asset && (
                        <div
                          className="mx-auto"
                          style={{
                            maxWidth: app.logo.asset.width / 2 + "px",
                          }}
                        >
                          <GatsbyImage
                            image={app.logo.asset.gatsbyImageData}
                            alt={`${app.title} logo`}
                            loading="lazy"
                          />
                        </div>
                      )}
                    </Link>
                  ) : (
                    <Link
                      to={`/real-estate-software-integrations/`}
                      className=" relative flex h-32 items-center justify-center rounded-4xl bg-white  shadow-5xl md:h-[168px] md:py-[4px] md:px-[10px]"
                      key={i}
                    >
                      {/* START: APP STATUS BADGE */}
                      {app.statusText !== null &&
                      app.statusText !== "coming soon" ? (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-primary-700 px-[10px] py-[4px] text-sm text-xs  font-medium capitalize text-white md:top-[15px] md:right-[15px] ">{`${app.statusText}`}</span>
                      ) : app.statusText === "coming soon" ? (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-[#83B813] px-[10px] py-[4px] text-sm  text-xs font-medium capitalize text-white md:top-[15px] md:right-[15px]">{`${app.statusText}`}</span>
                      ) : (
                        <span className="absolute right-5 top-[10px] z-10 inline-block rounded-full bg-transparent px-[10px] py-[4px] text-sm  text-xs font-medium  text-transparent md:top-[15px] md:right-[15px]">{`${app.statusText}`}</span>
                      )}
                      {/*END: APP STATUS BADGE */}

                      {app.logo && app.logo.asset && (
                        <div
                          className="mx-auto"
                          style={{
                            maxWidth: app.logo.asset.width / 2 + "px",
                          }}
                        >
                          <GatsbyImage
                            image={app.logo.asset.gatsbyImageData}
                            alt={`${app.title} logo`}
                            loading="lazy"
                          />
                        </div>
                      )}
                    </Link>
                  );
                })}
            </div>
          </div>
          <header className="mx-auto text-center md:max-w-[720px]">
            <h2>Don’t see your system?</h2>
            <p>Let us know—we can create a custom solution!</p>
            <ButtonSolid modal="modal-contact" text="Schedule a Call" />
          </header>
        </div>
      </section>

      <DesignedForRealtors />
      <AccelerateYourBusiness />
      <CallToAction />
      <FiltersModal
        appCategoriesNodes={appCategoriesNodes}
        setFilterArr={setFilterArr}
        filterArr={filterArr}
        statusFilterArr={statusFilterArr}
        setStatusFilterArr={setStatusFilterArr}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    heroImage: file(relativePath: { eq: "4.0 Apps/si-hero.svg" }) {
      publicURL
    }
    searchIcon: file(relativePath: { eq: "4.0 Apps/si-search.svg" }) {
      publicURL
    }
    filterIcon: file(relativePath: { eq: "4.0 Apps/si-filter.svg" }) {
      publicURL
    }
    backIcon: file(relativePath: { eq: "4.0 Apps/si-back.svg" }) {
      publicURL
    }
    apps: allSanityApps(sort: { fields: title, order: ASC }) {
      edges {
        node {
          statusText
          title
          logo {
            asset {
              width
              gatsbyImageData
            }
          }
          appCategories {
            id
            title
          }
          slug {
            current
          }
        }
      }
    }
    appCategories: allSanityAppCategories(sort: { fields: title }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export default Page;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonGhost from "../Button/ButtonGhost";

const DesignedForRealtors = ({ headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div className="order-2 md:order-1">
            <HeadingTag>
              Designed Exclusively For Real Estate Agents & Their Teams
            </HeadingTag>
            <p>
              Real Synch’s real estate workflow automation software was built in
              collaboration with residential real estate brokers and teams. Our
              tailored integrations and automations help teams of all sizes save
              time, lower costs, and lose the headaches of working within
              multiple systems.
            </p>

            <ButtonGhost href="/how-it-works/" text="How it Works" />
          </div>
          <div className="order-1 md:order-2">
            <StaticImage
              src="../../images/0.0 Repeating Modules/Designed For Realtors.png"
              loading="lazy"
              width={560}
              height={560}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DesignedForRealtors;

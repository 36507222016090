import React from "react";

function MobileFilters({ iconUrl, setFilterArr, setStatusFilterArr }) {
  const handleCleanFilter = () => {
    setFilterArr([]);
    setStatusFilterArr([]);
  };

  return (
    <div className="flex justify-between bg-white px-5 py-10 lg:hidden">
      <div
        className="flex cursor-pointer gap-x-3 text-typography-heading"
        data-modal-open="modal-filters"
      >
        <img src={iconUrl} />
        <div>Filters</div>
      </div>
      <div
        className="cursor-pointer text-sm font-bold leading-[20px] text-typography-heading underline"
        onClick={handleCleanFilter}
      >
        Reset Filters:
      </div>
    </div>
  );
}

export default MobileFilters;
